<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="2">
              <CSelect
                :placeholder="$t('search_a') + $t('device')"
                :options="devicesOptions"
                :value.sync="searchData.device_id"
                :isValid="valid_searchData_devicek_id"
              />
            </CCol>
            <CCol col="12" sm="3">
              <CSelect
                :placeholder="$t('search_a') + $t('phone_book')"
                :options="phoneBooksOptions"
                :value.sync="searchData.phone_book_id"
                :isValid="valid_searchData_phone_book_id"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CSelect
                :placeholder="$t('search_a') + $t('tag')"
                :options="tagsOptions"
                :value.sync="searchData.tag_id"
                :isValid="valid_searchData_tag_id"
              />
            </CCol>
            <CCol col="12" sm="3">
              <date-picker
                v-model="searchData.date_range"
                :shortcuts="false"
                :editable="false"
                :clearable="false"
                :not-after="$moment()"
                range-separator="~"
                range
                width="100%"
                value-type="date"
                format="YYYY-MM-DD"
                :input-class="['form-control', 'bg-white', valid_searchData_date_range ? 'is-valid' : 'is-invalid']"
              />
              <small class="form-text text-muted w-100">
                {{ $t('search_is_limited_to_a_date_range_of_up_to_one_month') }}
              </small>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block :disabled="
    (valid_searchData_devicek_id || valid_searchData_phone_book_id || valid_searchData_tag_id) && valid_searchData_date_range ? false : true" @click="getDialingLogs()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <vue-excel-xlsx
                class="btn btn-primary btn-block"
                :disabled="data.length === 0"
                :data="excelData()"
                :columns="excelColumns"
                :fileName="excelFilename()"
                :sheetName="$t('phone_search_results')"
                >
                {{ $t('export_search_results') }}
              </vue-excel-xlsx>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="number" slot-scope="props">
              {{ `${props.row.number}` }} {{ props.row.tags.length > 0 ? `(${$_.pluck(props.row.tags, 'name').join(', ')})` : '' }}
            </font>
            <font slot="status" slot-scope="props">
              {{ props.row.connected ? `${$t('connected')} (${props.row.duration} ${$t('seconds')})` : `${$t('not_connected')}` }}
            </font>
            <font slot="created_at" slot-scope="props">
              {{ $moment(props.row.created_at).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
          </v-client-table>
        </CCardBody>
      </CCard>

    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'dialingLogs',
  components: {
  },
  data () {
    return {
      columns: [ 'admin_name', 'device_name', 'phone_book_name', 'number', 'status', 'created_at' ],
      data: [],
      options: {
        headings: {
          admin_name: this.$t('account_name'),
          device_name: this.$t('device'),
          phone_book_name: this.$t('phone_book'),
          number: this.$t('phone_number'),
          status: this.$t('status'),
          created_at: this.$t('create_time')
        },
        sortable: [ 'admin_name', 'device_name', 'phone_book_name', 'number', 'status', 'created_at' ],
        filterable: [ 'admin_name', 'device_name', 'phone_book_name', 'number', 'status', 'created_at' ]
      },
      excelColumns: [
        { label: this.$t('account_name'), field: 'admin_name' },
        { label: this.$t('device'), field: 'device_name' },
        { label: this.$t('phone_book'), field: 'phone_book_name' },
        { label: this.$t('phone_number'), field: 'number' },
        { label: this.$t('status'), field: 'status' },
        { label: this.$t('create_time'), field: 'created_at', dataFormat: v => this.$moment(v).format('YYYY-MM-DD HH:mm:ss') }
      ],
      devicesOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('device') + '...' }
      ],
      phoneBooksOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('phone_book') + '...' }
      ],
      tagsOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('tag') + '...' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { device_id: null, phone_book_id: null, tag_id: null, date_range: [this.$moment({hour: 0}).subtract(7, 'd'), this.$moment({hour: 0})] },
    }
  },
  computed: {
    valid_searchData_devicek_id () {
      return this.searchData.device_id ? true : (this.searchData.phone_book_id || this.searchData.tag_id ? null : false)
    },
    valid_searchData_phone_book_id () {
      return this.searchData.phone_book_id ? true : (this.searchData.device_id || this.searchData.tag_id ? null : false)
    },
    valid_searchData_tag_id () {
      return this.searchData.tag_id ? true : (this.searchData.phone_book_id || this.searchData.device_id ? null : false)
    },
    valid_searchData_date_range () {
      return this.searchData.date_range[0] && this.searchData.date_range[1] && Math.abs(this.$moment(this.searchData.date_range[1]).endOf('d').diff(this.searchData.date_range[0], 'months', true)) <= 1 ? true : false
    },
  },
  mounted: function () {
    this.getDevices()
    this.getPhoneBooks()
    this.getTags()
  },
  methods: {
    getDevices () {
      const loader = this.$loading.show()
      this.$store.dispatch('getDevices').then(res => {
        this.devicesOptions = [{ value: null, label: this.$t('please_select_a') + this.$t('device') + '...' }]
        for (const i in res) {
          this.devicesOptions.push({ value: res[i].id, label: res[i].name })
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    getPhoneBooks () {
      const loader = this.$loading.show()
      this.$store.dispatch('getPhoneBooks').then(res => {
        this.phoneBooksOptions = [{ value: null, label: this.$t('please_select_a') + this.$t('phone_book') + '...' }]
        for (const i in res) {
          this.phoneBooksOptions.push({ value: res[i].id, label: res[i].name + ' (' + res[i].admin_name + ')', name: res[i].name })
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    getTags () {
      const loader = this.$loading.show()
      this.$store.dispatch('getTags').then(res => {
        this.tagsOptions = [{ value: null, label: this.$t('please_select_a') + this.$t('tag') + '...' }]
        for (const i in res) {
          this.tagsOptions.push({ value: res[i].id, label: res[i].name })
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    getDialingLogs () {
      if (!this.valid_searchData_devicek_id && !this.searchData.phone_book_id && !this.searchData.tag_id) {
        this.$swal('Warning', this.$t('please_select_a') + this.$t('device') + this.$t('or') + this.$t('phone_book') + this.$t('or') + this.$t('tag'), 'warning')
        return
      }
      if (!this.valid_searchData_date_range) {
        this.$swal('Warning', this.$t('search_is_limited_to_a_date_range_of_up_to_one_month'), 'warning')
        return
      }
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      const searchData = this.$_.clone(this.searchData)
      searchData.date_range[1] = this.$moment(searchData.date_range[1]).endOf('d')
      this.$store.dispatch('getDialingLogs', this.$_.clone(searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    excelData () {
      return this.data
    },
    excelFilename () {
      const device_name = this.searchData.device_id ? this.$_.find(this.devicesOptions, option => option.value === this.searchData.device_id).label : 'AllDevices'
      const phone_book_name = this.searchData.phone_book_id ? this.$_.find(this.phoneBooksOptions, option => option.value === this.searchData.phone_book_id).name : 'AllPhoneBooks'
      const tag_name = this.searchData.tag_id ? this.$_.find(this.tagsOptions, option => option.value === this.searchData.tag_id).label : 'AllTags'
      return `${this.$route.name}-[${device_name}]_[${phone_book_name}]_${tag_name}_${this.$moment(this.searchData.date_range[0]).format('YYYYMMDD')}${this.$moment(this.searchData.date_range[1]).format('YYYYMMDD')}_export_${this.$moment().unix()}`
    },
  }
}
</script>
